import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'

import PricingCard from '@/components/Pricing/PricingCard'
import ValueCalculator from '@/components/ValueCalculator'
import Layout from '@/components/Layout'

import SEO from '@/helpers/SEOHelper.js'

export const PricingPageTemplate = ({ data, pricing }) => {
  return (
    <div className='full-height'>
      <section className='flex hero items-center justify-center pt-56 mb-10'>
        <div className='container text-center mx-auto relative'>
          <h1 className='title-1 font-bold max-w-2xl leading-none text-center mx-auto mb-6'>
            {ReactHtmlParser(data.hero.heading)}
          </h1>
          <p className='max-w-xl mx-auto mb-10 text-gray'>
            {ReactHtmlParser(data.hero.description)}
          </p>
        </div>
      </section>
      <section className='container-sm pb-20'>
        <div className='flex items-start justify-center space-y-5 md:space-y-0 flex-col md:space-x-5 md:flex-row'>
          {data.pricing.map((item) => {
            let pricingItem = pricing.find(
              (el) => el.node.frontmatter.title === item,
            )
            return <PricingCard content={pricingItem} key={pricingItem.id} />
          })}
        </div>
      </section>

      <ValueCalculator></ValueCalculator>
    </div>
  )
}

const PricingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { edges: pricing } = data.pricing

  return (
    <Layout>
      <Helmet>
        <meta
          name='description'
          content={SEO.description(frontmatter.seo.description)}
        />
        <meta property='og:title' content={SEO.title(frontmatter.seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(frontmatter.seo.description)}
        />
        <meta property='og:image' content={SEO.image('null')} />
        <title>{SEO.title(frontmatter.seo.title)}</title>
      </Helmet>
      <PricingPageTemplate data={frontmatter} pricing={pricing} />
    </Layout>
  )
}

PricingPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default PricingPage

export const pageQuery = graphql`
  query PricingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "pricing" } }) {
      frontmatter {
        title
        hero {
          heading
          description
        }
        pricing
        seo {
          title
          description
        }
      }
    }
    pricing: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "pricing" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            order
            price
            isPromoted
            shortDescription
            description
            button
          }
        }
      }
    }
  }
`
