import React, { Component } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

export default class ValueCalculator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      amountOfPlayers: null,
      costPerPlayer: null,
      isCalculated: false,
    }

    this.handleInput = this.handleInput.bind(this)
    this.showCalculation = this.showCalculation.bind(this)
    this.hideCalculation = this.hideCalculation.bind(this)
  }

  handleInput(e) {
    let value = parseInt(e.target.value)
    let cost = 300 / value
    this.setState({
      amountOfPlayers: value || null,
      costPerPlayer: cost.toFixed(2) || null,
    })
  }

  showCalculation() {
    if (typeof this.state.amountOfPlayers === 'number') {
      this.setState({ isCalculated: true })
    }
  }

  hideCalculation() {
    this.setState({ isCalculated: false })
  }

  render() {
    return (
      <section
        className={`${
          this.state.isCalculated ? 'bg-green' : 'bg-accent'
        } py-20 text-center ValueCalculator`}>
        <div className='container'>
          <h2 className='text-h3 md:text-h2 text-white mb-8'>
            Calculate the value for your club!
          </h2>
          <SwitchTransition mode='out-in'>
            <CSSTransition
              key={this.state.isCalculated}
              classNames='fade'
              addEndListener={(node, done) =>
                node.addEventListener('transitionend', done, false)
              }>
              <div>
                {!this.state.isCalculated ? (
                  <>
                    <p className='text-h4 md:text-h5 text-white'>
                      Approximately how many players trial at your club?
                    </p>
                    <input
                      type='number'
                      min='1'
                      placeholder='35'
                      className='ValueCalculator__field'
                      value={this.state.amountOfPlayers}
                      onInput={this.handleInput}
                    />
                    <div className='mt-6'>
                      <button
                        className='btn btn-white'
                        onClick={this.showCalculation}>
                        Calculate Value
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='ValueCalculator__result'>
                      ${this.state.costPerPlayer} per player
                    </div>
                    <button
                      className='btn btn-white'
                      onClick={this.hideCalculation}>
                      Change amount of players
                    </button>
                  </>
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </section>
    )
  }
}
