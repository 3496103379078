import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'

export default class PricingCard extends Component {
  render() {
    const content = this.props.content.node.frontmatter
    let cardClass =
      content.isPromoted === 'true'
        ? 'pricing-card pricing-card--promoted max-w-xl'
        : 'pricing-card max-w-xl'
    let btnClass = `btn w-full text-center mt-8 ${
      content.isPromoted === 'true' ? 'btn-primary' : 'btn-outlined'
    }`
    return (
      <div className={cardClass}>
        <div className='pricing-card__header'>
          <h2 className='pricing-card__title'>{content.title}</h2>
          {content.isPromoted === 'true' ? (
            <div className='badge'>Most Popular</div>
          ) : (
            ''
          )}
        </div>
        <div className='pricing-card__description'>
          {content.shortDescription}
        </div>
        <div className='pricing-card__price'>${content.price}</div>
        <ReactMarkdown
          source={content.description}
          className='pricing-card__features'
        />
        <a href='https://app.selectrnetball.com.au/signup' className={btnClass}>
          {content.button}
        </a>
      </div>
    )
  }
}
